import { Alert, Box, Button, Typography, Link as MuiLink, Paper } from "@mui/material";
import { FC, MouseEventHandler, useEffect, useState } from "react";
import { ErrorOption, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { ConfirmPhoneButton } from "components/buttons";
import {
  CompanyNameField,
  InnOrBikField,
  PhoneField,
  PinCodeField,
  EmailField
} from "components/form";
import { Paths } from "routes/paths";
import { checkConfirmPhoneDisabled } from "services/checkConfirmPhoneDisabled";
import { filterStringNumbers } from "utils/filterStringNumbers";

import { useGetPinCodeMutation, useRegisterMutation } from "../../hooks";

import { AgreementCheckboxField } from "./AgreementCheckbox";
import { ConfirmRegistrationDialog } from "./ConfirmRegistrationDialog";
import { SignUpFormInputs } from "./types";

const defaultValues: SignUpFormInputs = {
  name: "",
  innOrBik: "",
  phone: "",
  email: "",
  pinCode: "",
  agreement: false
};

export const SignUpForm: FC = () => {
  const { t } = useTranslation();
  const [isConfirmRegistrationOpen, setIsConfirmRegistrationOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setFocus,
    setError,
    trigger,
    clearErrors,
    watch
  } = useForm<SignUpFormInputs>({ defaultValues });

  const {
    mutation: { mutate: getPinCode, isSuccess: isGetPinCodeSuccess },
    repeatSeconds,
    isRepeat
  } = useGetPinCodeMutation();

  const { mutate: register } = useRegisterMutation();

  const setServerError = (options: ErrorOption) => {
    setError("root.serverError", {
      ...options,
      message: options.message || t("common.errors.unknown_error")
    });
  };

  const handleSubmitValid: SubmitHandler<SignUpFormInputs> = (data) => {
    register(
      {
        email: data.email,
        innOrBik: data.innOrBik,
        name: data.name,
        pinCode: data.pinCode,
        phone: filterStringNumbers(data.phone)
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (!error) {
            setIsConfirmRegistrationOpen(true);
            return;
          }

          switch (error.code) {
            case "auth_pincode_incorrect":
              setError("pinCode", { message: t("auth.errors.auth_pincode_incorrect") });
              break;

            case "auth_user_already_exists":
              setServerError({
                type: error.code,
                message: t("auth.errors.auth_user_already_exists")
              });
              break;

            default:
              setServerError({
                type: error.code,
                message: error?.message
              });
              break;
          }
        },
        onError: (error) => setServerError({ message: error?.message })
      }
    );
  };

  const handleConfirmClick: MouseEventHandler<HTMLButtonElement> = async () => {
    const phone = getValues().phone;

    getPinCode(
      { phone: filterStringNumbers(phone) },
      {
        onError: (error) => setServerError({ message: error?.message })
      }
    );
  };

  useEffect(() => {
    if (isGetPinCodeSuccess) {
      setFocus("pinCode");
    }
  }, [isGetPinCodeSuccess, setFocus]);

  const serverErrorMessage = errors.root?.serverError?.message;

  return (
    <Paper
      component='form'
      onSubmit={handleSubmit(handleSubmitValid)}
      sx={{ padding: "45px 31px 41px 39px", width: "1170px", maxWidth: "100%" }}
    >
      <Typography variant='h2' component='h1'>
        {t("auth.sign_up")}
      </Typography>

      {serverErrorMessage && (
        <Alert severity='error' sx={{ mt: 2 }}>
          {serverErrorMessage}
        </Alert>
      )}

      <Box
        sx={{
          mt: "27px",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignItems: "start",
          columnGap: "31px",
          rowGap: "50px",
          "@media (min-width: 1100px)": {
            gridTemplateColumns: "1fr 1fr 1fr"
          }
        }}
      >
        <CompanyNameField
          sx={{ "@media (min-width: 1100px)": { gridColumn: "1 / 4" } }}
          name='name'
          control={control}
        />

        <InnOrBikField
          sx={{ "@media (min-width: 1100px)": { gridColumn: "1 / 4" } }}
          name='innOrBik'
          control={control}
        />

        <PhoneField name='phone' control={control} trigger={trigger} clearErrors={clearErrors} />

        <PinCodeField name='pinCode' control={control} disabled={!isGetPinCodeSuccess} />

        <ConfirmPhoneButton
          disabled={checkConfirmPhoneDisabled({
            repeatSeconds,
            phone: watch("phone"),
            invalid: "phone" in errors
          })}
          repeatSeconds={repeatSeconds}
          isRepeat={isRepeat}
          onClick={handleConfirmClick}
        />

        <EmailField
          sx={{ "@media (min-width: 1100px)": { gridColumn: "1 / 3" } }}
          name='email'
          trigger={trigger}
          control={control}
        />
      </Box>

      <AgreementCheckboxField control={control} />

      <MuiLink
        sx={{ ml: "37px" }}
        href={window.PRIVACY_POLICY_URL ?? "#"}
        target='_blank'
        rel='noopener noreferrer'
        variant='body1'
      >
        {t("auth.personal_info_rules")}
      </MuiLink>

      <Box
        sx={{
          mt: "64px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "26px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "26px"
          }}
        >
          <Button type='submit' disabled={!isValid} variant='contained' sx={{ width: "310px" }}>
            {t("common.continue")}
          </Button>

          <Button component={Link} to={Paths.SignIn} variant='outlined' sx={{ width: "197px" }}>
            {t("common.cancel")}
          </Button>
        </Box>

        <MuiLink component={Link} to={Paths.Feedback}>
          {t("common.feedback")}
        </MuiLink>
      </Box>

      <ConfirmRegistrationDialog
        open={isConfirmRegistrationOpen}
        onClose={() => navigate("/sign-in")}
      />
    </Paper>
  );
};
